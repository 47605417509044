import { Router } from '@reach/router';
import type * as React from 'react';

const Route: React.FC<{ path: string; node: React.ReactNode }> = ({ node }) => {
  return <>{node}</>;
};

export const BasePath: React.FC<
  React.PropsWithChildren<{
    basepath: string;
  }>
> = ({ basepath, children }) => {
  return (
    <Router basepath={basepath}>
      <Route path="/*" node={children} />
    </Router>
  );
};
