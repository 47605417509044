import type { RouteComponentProps } from '@reach/router';
import { isRedirect, LocationProvider } from '@reach/router';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { BasePath } from '../basepath';
import type { RootComponentType } from '../root';

const RedirectHandler = class extends React.PureComponent<
  React.PropsWithChildren<RouteComponentProps>
> {
  public componentDidCatch(error: Error): void {
    // this will create a hard page reload if this is a same-domain
    // redirect to an external application
    if (isRedirect(error)) {
      try {
        const uri = new URL(error.uri);
        const loc = window.location;
        if (uri.protocol === loc.protocol && uri.host === loc.host) {
          loc.href = error.uri;
          return;
        } else if (error.uri.startsWith('http')) {
          // if reach-router does the external redirect, we end with
          // with a redirect-loop. Therefore we do replace the
          // target here already instead of re-throwing
          loc.href = error.uri;
          return;
        }
      } catch {
        //
      }
    }

    throw error;
  }

  public render(): JSX.Element {
    return <>{this.props?.children}</>;
  }
};

export const BrowserRoot: RootComponentType = ({ basePath, children }) => {
  return (
    <RedirectHandler>
      <LocationProvider>
        <BasePath basepath={basePath}>
          <BrowserRouter basename={basePath}>{children}</BrowserRouter>
        </BasePath>
      </LocationProvider>
    </RedirectHandler>
  );
};
