import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';

import type {
  RuntimeHost,
  Xinglet,
  XingletFunction,
} from '@xing-com/crate-xinglet';
import type { InternalHost } from '@xing-com/crate-xinglet/internal';

// Note: importing the fragment type here violates some settings, but
// we want to have them in the repo root
/* eslint-disable  */
// @ts-ignore
import fragmentTypes from '../../../../fragmentTypes.json';
/* eslint-enable */

import { BrowserRoot } from './browser/root';
import type { RootComponentType } from './root';
import type { ServerRoot } from './server/root';
import type { HopsUploadRequestMutationVariables } from './upload-request-mutation.gql-types';

function importRoot(host: InternalHost): RootComponentType {
  if (host.isServer) {
    const { value: module } = host.importModule<{
      ServerRoot: typeof ServerRoot;
    }>('@xing-com/crate-core-hops-environment', 'server-root');

    return module!.ServerRoot;
  } else {
    return BrowserRoot;
  }
}

export interface HopsEnvironmentExtension {
  executeCommand:
    | XingletFunction<
        HopsEnvironment,
        '@xing-com/crate-core-hops-environment.apolloClient'
      >
    | XingletFunction<
        HopsEnvironment,
        '@xing-com/crate-core-hops-environment.uploadFile'
      >;
}

export type HopsEnvironmentProps = React.PropsWithChildren<{
  basePath?: string;
}>;

export default class HopsEnvironment implements Xinglet {
  private client?: ApolloClient<unknown>;

  public constructor(private host: RuntimeHost) {}

  public '@xing-com/crate-core-hops-environment.apolloClient'():
    | ApolloClient<unknown>
    | undefined {
    return this.client;
  }

  public async '@xing-com/crate-core-hops-environment.uploadFile'(
    application: HopsUploadRequestMutationVariables['application'],
    file: File,
    errorHandler?: (id: string) => void
  ): Promise<string | null> {
    if (!this.client) {
      throw new Error('No upload outside of hops environment');
    }
    return await (
      await import('./upload')
    ).uploadFile(this.client, application, file, errorHandler);
  }

  public getComponent(
    host: InternalHost
  ): React.ComponentType<HopsEnvironmentProps> {
    const Root = importRoot(host);

    const cache = new InMemoryCache({
      possibleTypes: fragmentTypes,
    });

    const client = new ApolloClient({
      cache,
      connectToDevTools: host.isPreview,
      link: new HttpLink({
        fetch: host.fetch,
        uri: host.config.xingOneEndpoint,
      }),
      ssrMode: host.isServer,
    });

    if (host.isServer) {
      host.storeServerData?.('APOLLO_STATE', () => {
        try {
          return cache.extract();
        } finally {
          (async () => {
            cache.gc({
              resetResultCache: true,
              resetResultIdentities: true,
            });
            await cache.reset();
            await client.clearStore();
          })();
        }
      });

      const { APOLLO_STATE: state } = host.config.serverData ?? {};

      if (state) {
        cache.restore(state as NormalizedCacheObject);
      }
    } else {
      const state: NormalizedCacheObject = {
        ...(host.loadServerData?.('APOLLO_STATE') ?? {}),
      };

      cache.restore(state);

      this.client = client;
    }

    const { language } = host.config;
    const { languageData: messages = {} } = host.runtime;
    const helmetContext = host.isServer ? host.helmetContext : {};

    return ({ basePath = '/', children }) => {
      return (
        <ApolloProvider client={client}>
          <HelmetProvider context={helmetContext}>
            <IntlProvider
              locale={language}
              messages={messages}
              textComponent={React.Fragment}
            >
              <Root basePath={basePath}>{children}</Root>
            </IntlProvider>
          </HelmetProvider>
        </ApolloProvider>
      );
    };
  }
}
